<template>
  <div class="angleControlBox mt16">
    <div class="angleControlBg w99 h99 dib">
      <div class="dib wih100 ht100 pr">
        <div class="angleControlLB tc w40 ml50 mt40 h30" />
        <img class="pa top0 left21" src="@/assets/images/PagerP1/LBT2.png" alt />
        <div class="light pa left30 top20" :style="`transform: rotate(${90 - rotate}deg);`">
          <img src="~@/assets/images/PagerP1/LB2.png" alt />
        </div>
        <div class="iconfont icon-zuo1 pa top40 left15 c08c"></div>
        <div class="rolate iconfont icon-zuo1 pa top40 right15 c08c"></div>
      </div>
    </div>

    <div class>
      <img class src="@/assets/images/PagerP1/jd.png" alt />
      <span class="dib vas cf">角度调节</span>
    </div>
    <div class="h56 wih100 bg tc">
      <div class="AngleControl dib w100 h56 tc pr">
        <div
          class="left5 top18 pa w28 lh28 h28 left cp"
          @mousedown="rotateFn(1)"
          @mouseup="stopFn()"
        >
          <div class="iconfont icon-zuo1"></div>
        </div>
        <div
          class="pa right5 top18 w28 lh28 h28 right cp"
          @mousedown="rotateFn(-1)"
          @mouseup="stopFn()"
        >
          <div class="rolate mr5 iconfont icon-zuo1"></div>
        </div>
      </div>
    </div>
    <div class="mt12 cf f16">
      <img class="dib w24 h24 mr10" src="@/assets/images/PagerP1/Voice.png" alt />
      <div class="dib vas">音量调节</div>
      <div class="jcsb pl20 mr10">
        <div
          class="dib rightBtn mr5"
          @mousedown="handleChangeVolume(-1)"
          @mouseup="stophandleChangeVolume()"
        >
          <img class src="@/assets/images/PagerP1/del.png" alt />
        </div>
        <el-progress class="w120 lh1.3" :text-inside="true" :percentage="volume" :stroke-width="17"></el-progress>
        <div
          class="dib rightBtn ml5"
          @mousedown="handleChangeVolume(1)"
          @mouseup="stophandleChangeVolume()"
        >
          <img class src="@/assets/images/PagerP1/plus.png" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CmdSetSystemVolume from "@/utils/PagerP1/cmd_set_system_volume"; //设置系统音量
import CmdServoAngle from "@/utils/PagerP1/cmd_servo_angle"; //设置偏转角
export default {
  data() {
    return {
      // volume: 0,
      // rotate: 0,
      time: "",
      handleChangeVolumeTime: ""
    };
  },
  props:{
    rotate:{
      type: Number,
      default: 0
    },
    volume:{
      type: Number,
      default: 0
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.volume = this.PagerP1.volume;
    //   this.rotate = this.PagerP1.angle;
    // }, 1000);
  },
  inject: ["PagerP1", "PoliceKey"],
  methods: {
    stopFn() {
      clearInterval(this.time);
    },
    rotateFn(type) {
      clearInterval(this.time);
      if (type == 1) {
        this.time = setInterval(() => {
          if (this.rotate < 60) {
            this.rotate++;
            let message = {
              data: this.rotate,
              type: 2314
            };
            this.mqtt_publish(message);
            // this.rotate++;
          }
        }, 100);
      } else {
        this.time = setInterval(() => {
          if (this.rotate > 0) {
            this.rotate--;
            let message = {
              data: this.rotate,
              type: 2314
            };
            this.mqtt_publish(message);
            // this.rotate--;
          }
        }, 100);
      }
    },
    // 控制音量 start
    handleChangeVolume(cate) {
      this.stophandleChangeVolume();
      this.handleChangeVolumeTime = setInterval(() => {
        if (
          (this.volume == 0 && cate == -1) ||
          (this.volume == 100 && cate == 1)
        ) {
          return;
        }
        this.volume += cate;
        let message = {
          data: this.volume,
          type: 2315
        };
        this.mqtt_publish(message);
      }, 100);
    },
    stophandleChangeVolume() {
      clearInterval(this.handleChangeVolumeTime);
    },
    async mqtt_publish(message) {
      if (this.PoliceKey()) {
        let key = await this.PoliceKey();
        this.PagerP1.mqtt.client.publish(
          `SPEAK/OBTAIN/${key}-01`,
          new TextEncoder().encode(JSON.stringify(message)),
          {
            qos: 0
          },
          e => {
            // console.log("发送指令成功:", message);
          }
        );
      }
    },
    mqtt_json2uint8array(data) {
      if (!data) {
        return data;
      }
      let { encoder } = this.PagerP1.mqtt;
      if (!encoder) {
        encoder = new TextEncoder();
      }
      let uint8array = encoder.encode(JSON.stringify(data));
      return uint8array;
    }
    // 控制音量 end
  }
};
</script>

<style lang="scss" scoped>
.angleControlBox {
  width: 127px;
  margin: 0 auto;
  text-align: center;
  .left21 {
    z-index: 10;
  }
  .angleControlBg {
    background-image: url("~@/assets/images/PagerP1/bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .angleControlLB {
      transform-origin: 50% 0px;
      // background-image: url("~@/assets/images/PagerP1/LB2.png");
      background-repeat: no-repeat;
    }
  }
  .rightBtn {
    box-sizing: border-box;
    border-radius: 50%;
    // border: 1px solid #08c2d1;
    width: 16px;
    height: 16px;
    // line-height: 20px;
    // text-align: center;
    cursor: pointer;
    // font-size: 20px;
    padding-left: 1px;

    &:hover {
      opacity: 0.7;
    }
  }
  .AngleControl {
    background-image: url("~@/assets/images/PagerP1/jdbj2.png");
    background-repeat: no-repeat;
    
    background-size: 100%;
    .left {
      border-radius: 50%;
      color: #3d4654;

      background: #9aabbd;
    }
    .left:hover {
      color: #08c2d1;
    }
    .right {
      border-radius: 50%;
      color: #3d4654;
      background: #9aabbd;
      margin-right: 15px;
    }
    .right:hover {
      color: #08c2d1;
    }
  }
}
.rolate {
  transform: rotateY(180deg);
}
.c08c {
  color: #08c2d1;
}
/deep/ .el-progress-bar__innerText {
  color: #000;
}
.right5 {
  right: -10px;
}
</style>
