const jspack = require("jspack").jspack

class Mavlink20Header {
    msgId;
    mlen = 0;
    incompat_flags = 0;
    compat_flags = 0;
    seq = 0;
    srcSystem = 0;
    srcComponent = 0;

    constructor(msgId, mlen = 0, seq = 0, srcSystem = 0, srcComponent = 0) {
        this.msgId = msgId
        this.mlen = mlen
        this.seq = seq
        this.srcSystem = srcSystem
        this.srcComponent = srcComponent
    }

    pack() {
        return jspack.Pack('BBBBBBBHB', [253, this.mlen, this.incompat_flags, this.compat_flags, this.seq, this.srcSystem, this.srcComponent, ((this.msgId & 0xFF) << 8) | ((this.msgId >> 8) & 0xFF), this.msgId >> 16]);
    }
}

module.exports = Mavlink20Header