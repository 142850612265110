
class Mavlink20BadData {
    id = -1;
    constructor(data, reason) {
        this.data = data;
        this.reason = reason;
        this.msgbuf = data;
    }
}

module.exports = Mavlink20BadData