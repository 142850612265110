const jspack = require("jspack").jspack
const Mavlink20 = require("./mavlink20");

class CmdSaveFile extends Mavlink20 {
    format = '<BB100A';
    msg_id = 100103;
    order_map = [0, 1, 2];
    crc_extra = 49;
    name = 'CMD_SAVE_FILE';

    channel = null;
    result = null;
    filename = "";

    constructor(channel, filename, result) {
        super()
        this.channel = channel
        this.filename = filename
    }

    pack() {
        return super.pack(this, this.crc_extra, jspack.Pack(this.format, [this.channel, this.result, this.filename]))
    }

}


export default CmdSaveFile