const jspack = require("jspack").jspack
const Mavlink20 = require("./mavlink20");

class CmdRecordName extends Mavlink20 {

    format = '<B100A';
    msg_id = 100094;
    order_map = [0, 1];
    crc_extra = 224;
    srcSystem = 255;
    srcComponent = 190;

    name = 'CMD_RECORD_NAME';
    result = null;

    constructor(text, format) {
        super()
        this.text = text
        this.format = format
    }

    pack() {
        return super.pack(this, this.crc_extra, jspack.Pack(this.format, [this.result, this.text]))
    }
}

module.exports = CmdRecordName