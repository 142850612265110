const jspack = require("jspack").jspack
const Mavlink20 = require("./mavlink20");

class CmdServoAngle extends Mavlink20 {

    format = "<BB";
    msg_id = 100112;
    order_map = [0, 1];
    crc_extra = 33;
    name = "CMD_SERVO_ANGLE"
    // 0 ~ 60
    angle = 0;
    result = null;

    constructor(angle) {
        super()
        this.angle = angle
    }

    pack() {
        return super.pack(this, this.crc_extra, jspack.Pack(this.format, [this.angle, this.result]))
    }

}

module.exports = CmdServoAngle