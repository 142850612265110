const jspack = require("jspack").jspack
const Mavlink20 = require("./mavlink20");


class CmdStreamUpData extends Mavlink20 {

    format = '<B250A';
    msg_id = 100098;
    order_map = [0, 1];
    crc_extra = 14;
    name = 'CMD_STREAM_UP_DATA';

    constructor(len, data) {
        super()
        this.len = len
        this.data = data
    }

    pack() {
        return super.pack(this, this.crc_extra, jspack.Pack(this.format, [this.len, this.data]))
    }

}

module.exports = CmdStreamUpData