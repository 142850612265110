const jspack = require("jspack").jspack
import Mavlink20 from "./mavlink20"

// mavlink20.GPI_MEDIA_STREAM_UPLOAD_START = 10 // 流上传开始
// mavlink20.GPI_MEDIA_STREAM_UPLOAD_STOP = 11 // 流上传停止

class CmdStreamFunction extends Mavlink20 {

    format = '<BBB';
    msg_id = 100097;
    order_map = [0, 1, 2];
    crc_extra = 109;
    name = 'CMD_STREAM_FUNCTION';

    func = 0;
    type = 0;
    result = null;

    constructor(func, type, result) {
        super()
        this.func = func
        this.type = type
    }

    pack() {
        return super.pack(this, this.crc_extra, jspack.Pack(this.format, [this.func, this.type, this.result]))
    }

}

export default CmdStreamFunction